.lock-header {
    font-size: 1em;
    position: fixed;
    left: 0px;
    top: 0px;
    background: linear-gradient(180deg, rgb(20, 20, 20), rgba(165, 140, 254, 0));
    z-index: 99;
    height: 120px;
    width: 100%;
    /* Positions the image and links on opposite ends */
    /* Aligns items vertically */
    /* Ensures the header takes full width */
    animation: fadeIn 1s ease-in forwards;
    opacity: 0;
    /* Start with elements invisible */
}

.padded-header {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 0;
    height: 120px;
    color: #fff0;
}

.lock-footer {
    font-size: 1em;
    padding: 20px;
    position: fixed;
    text-align: left;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 1em;
    background: linear-gradient(0, rgb(20, 20, 20), rgba(165, 140, 254, 0));
}

.footer {
    font-size: 0.6em;
    animation: fadeIn 1s ease-in forwards;
    opacity: 0;
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; */
    align-items: center;
    justify-content: space-between;
    /* Start with elements invisible */
}

.footer a {
    font-weight: bolder;
    color: #fff;
    text-decoration: none;
    font-family: inherit;
}

.footer * {
    text-align: left;
    line-height: 1.4em;
}

.footer a:hover {
    background: linear-gradient(135deg, rgb(228, 124, 69), rgb(238, 77, 69));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.footer-link-img {
    height: 48px;
}

.menu-icon {
    cursor: pointer;
    display: flex;
    width: 32px;
    margin: 20px;
    transform: rotate(0deg);
    transition: transform 0.25s ease-out;
}

.nav-bar {
    transform: translateY(0%);
    transform: translateX(0%);
    transition: transform 0.3s ease-in-out, border 0.5s ease-in-out;
    position: absolute;
    right: 30px;
    top: 3px;
    margin: 20px;
    max-width: 60%;
    display: flex;
    flex-wrap: wrap; /* Prevent wrapping between words */
    justify-content: flex-end; /* Align items to the right */
    z-index: auto;
}

.nav-bar-closed {
    transform: translateX(120%);
}

.header-link {
    display: block;
    margin: 10px 20px;
    color: rgb(235, 233, 242);
    text-decoration: none;
}

.header-link:hover {
    background: linear-gradient(135deg, rgb(228, 124, 69), rgb(238, 77, 69));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

@media (max-width: 1200px) {
    .nav-bar {
        right: 24px;
        top: 20px;
        border-right: 3.5px solid white;
        background-color: rgb(31, 31, 38, 0.95);
        /* border-radius: 20px;   */
    }
    .nav-bar-closed {
        border-right: 3.5px solid rgba(255, 255, 255, 0);
        transform: translateY(-120%);
    }

    .padded-header {
        height: 0px;
    }

    .footer {
        flex-direction: column;
        justify-content: inherit;
        align-items: left;
    }
    .lock-header ul {
        font-size: 0.75em;
        display: flex;
        flex-direction: column;
        text-align: right;
    }
}

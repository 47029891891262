.flex_box {
    display: flex;
    text-align: center;
    font-size: 0.8em;
    overflow: hidden;
    cursor: pointer;
}

.flex_box img {
    min-width: 10rem;
    max-width: 100%;
    max-height: 100%;
    transition: height 0.7s;
}

.flex_box_content h3 {
    padding: 1em;
}

.flex_box_content {
    margin: 10px;
    margin-top: 1em;
    padding: 0;
    transition: flex 0.7s;
    overflow: hidden;
    border-radius: 2px;
    /* background: #ffffff09; */
}

.flex_box_text {
    margin: 0.6em;
}

@media (max-width: 1200px) {
    .flex_box {
        flex-direction: column;
    }

    .clip_height_mobile {
        height: 30vh;
        object-fit: cover;
        width: 100%;
    }

    .flex_box_content {
        margin: 1em 0px;
        border: #ffffff22 solid 1px;
        border-radius: 5px;
    }
}

@font-face {
    font-family: "Sansation";
    src: url("./assets/fonts/sansation/Sansation_Light.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik Glitch";
    src: url("./assets/fonts/Rubik_Glitch/RubikGlitch-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Reenie Beanie";
    src: url("./assets/fonts/Reenie_Beanie/ReenieBeanie-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik ";
    src: url("./assets/fonts/Rubik/Rubik-Black.ttf") format("truetype");
}

/* Defaults in all things */
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

body {
    font-family: "Sansation", sans-serif;
    background-color: rgb(31, 31, 38);
    font-size: xx-large;
    color: rgb(235, 233, 242);
    margin: auto;
    text-align: center;
    align-items: center;
    align-content: center;
    scroll-behavior: smooth;
    max-width: 100vw;
    overflow-x: hidden;
}

.quote {
    font-family: "Reenie Beanie", sans-serif;
    font-size: 1.4em;
    letter-spacing: 0.1rem;
}

.flip-colours {
    color: rgb(31, 31, 38);
    background-color: rgb(235, 233, 242);
}

/* Hide the default scrollbar */
::-webkit-scrollbar {
    width: 0.5rem;
    background-color: transparent;
}

/* Styling the scrollbar handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(135deg, rgb(20, 245, 174), rgb(165, 140, 254));
    /* Dark-grey color */
    border-radius: 10px;
    /* Rounded handle */
}

/* Super common styles */
.hardstuff {
    font-weight: lighter;
    font-family: "Rubik Glitch", sans-serif;
    background: linear-gradient(135deg, #a58cfe, #14f5ae);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    letter-spacing: 0.05em;
    word-spacing: 0.15em;
}

a {
    background: initial;
    color: #52a9ba;
    font-weight: bold;
    text-decoration: underline;
}
a:hover {
    background: linear-gradient(135deg, rgb(228, 124, 69), rgb(238, 77, 69));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.hardstuff-no-colour {
    font-weight: lighter;
    letter-spacing: 0.05em;
    word-spacing: 0.15em;
    font-family: "Rubik Glitch", sans-serif;
}

section {
    padding: 2vh 0 2vh 0;
    margin: 10vh 0 20vh 0;
    opacity: 0;
    animation-fill-mode: both;
    min-height: 60vh;
    /* Make sure the element retains the state after the animation */
    /* border: solid red 1px; */
}

.content {
    max-width: 66%;
    margin: 0 auto;
    margin-top: 3em;
    margin-bottom: 3em;
}
p {
    line-height: 1.33em;
}

h1 {
    font-size: 6vw;
    margin-bottom: 1rem;
}
.big-h1 {
    margin-top: 0.5em;
    font-size: 13rem;
}

h2 {
    font-size: 1.7em;
    margin: 0px 0px 1rem 0px;
}

h3 {
    font-size: 1.3em;
    margin: 0px 0px 1rem 0px;
}

h4 {
    font-size: 0.9em;
    font-weight: 900;
    margin: 0px 0px 1rem 0px;
}

/* Auto-scroll effect */
.scroll-container {
    display: inline-flex;
    font-size: 1.5em;
    overflow-y: hidden;
    justify-content: center;
    /* Center align items horizontally */
    /* margin: 100px; */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.build,
.test,
.scroll-item,
.repeat {
    animation: fadeIn 0.5s ease-in forwards;
    opacity: 0;
    /* Start with elements invisible */
}

.scroll-item {
    margin: 0 10px;
    animation: bounce 2s infinite;
    animation-delay: 1.5s;
}

.build {
    animation-delay: 0.5s;
}

.test {
    animation-delay: 1s;
}

.repeat {
    animation-delay: 2s;
}

@keyframes bounce {
    0% {
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
    }

    100% {
        transform: translateY(-100%);
        visibility: hidden;
        opacity: 0;
    }

    10%,
    90% {
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
    }
}

/* Intersection Observer Animation */

/* Transition Effects */

@keyframes fadeInUp {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeInUp 1.3s forwards;
}

.pillar-content {
    font-size: 1em;
    animation: fadeInUp 2s forwards;
    /* padding-top: 1em; */
    /* padding-bottom: 1em; */
}

.hoverable {
    transition: background-color 0.3s ease;
    padding: 10%;
    /* padding-bottom: 1em; */
    border-radius: 20px;
    font-size: 0.8em;
}

.hoverable:hover {
    background: rgba(255, 255, 255, 0.1);
}

button {
    font-size: 0.8em;
    cursor: pointer;
    background-color: rgb(235, 233, 242);
    transition: background-color 0.3s ease;
    color: rgb(31, 31, 38);
    margin: 0.5em;
    font-family: inherit;
    font-size: 1em;
    animation: fadeInUp 0.5s forwards;
    padding: 0.35em;
    /* padding-bottom: 1em; */
    border: solid 2px;
    border-image: linear-gradient(135deg, rgb(20, 245, 174), rgb(165, 140, 254)) 30;
    border-image-slice: 1;
    border-radius: 0;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center; /* center the content horizontally */
    align-items: center; /* center the content vertically */
}

button:hover {
    background: linear-gradient(135deg, rgb(228, 124, 69), rgb(238, 77, 69));
}

.desktop-only {
    display: inherit;
}

.mobile-only {
    display: none;
}

.stack_responsive {
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; */
    /* align-items: center; */
    justify-content: space-between;
}
.stack_responsive > * {
    margin: 20px;
    flex: 1;
    text-align: inherit; /* Reset text-align to inherit from parent */
    max-width: 50%;
    display: block;
    object-fit: contain;
}
.stack_responsive > :first-child {
    text-align: right;
}

.stack_responsive > :last-child {
    text-align: left;
}

@media (max-width: 1200px) {
    .content {
        max-width: 85%;
    }
    .stack_responsive {
        flex-direction: column;
    }

    .stack_responsive > * {
        flex: initial;
        margin: 0.5em auto;
        max-width: 100%;
    }

    .stack_responsive > :first-child {
        text-align: inherit;
    }

    .stack_responsive > :last-child {
        text-align: inherit;
    }
}

@media (max-width: 768px) {
    /* Specialist desktop styles here */

    section {
        margin: 5vh 0 5vh 0;
    }
    .content {
        max-width: 80%;
        font-size: 0.9em;
    }

    h1 {
        font-size: 1.4em;
        overflow: auto;
        margin: 20vh 0px 0px 0px;
    }

    h2 {
        font-size: 1.2em;
    }

    .big-h1 {
        font-size: 25vw;
    }

    .scroll-container {
        display: flex;
        flex-direction: column;
        font-size: 1.5em;
        overflow-y: hidden;
        justify-content: center;
        /* Center align items horizontally */
    }

    .desktop-only {
        display: none;
    }
    .mobile-only {
        display: initial;
    }

    .ReactCollapse--collapse {
        transition: height 1000ms;
    }
}
